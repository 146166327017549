import Vue from 'vue'
import Router from 'vue-router'
import db from 'utils/localstorage'
import request from 'utils/request'
import { determineIsMobile } from 'utils/tool'

// 全局Router异常处理
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => { if (typeof err !== 'undefined')console.log(err) })
}
Vue.use(Router)

Vue.prototype.$toDashboard = () => {
  window.location.href = DASHBOARD
}
Vue.prototype.$toLogin = () => {
  window.location.href = DASHBOARD + '/#/login'
}
Vue.prototype.$toCandidate = () => {
  window.location.href = CANDIDATE
}

let constRouter = [
  {
    path: '/',
    name: '首页',
    redirect: '/nWebHome'
  },
  {
    path: '/index',
    name: '首页',
    redirect: '/webHome'
  },
  {
    path: '/mWebHome',
    name: 'mWebHome',
    // meta: {
    //   title: '首页'
    // },
    component: () => import('@/views/mobile/mWebHome'),
    children: [
      {
        path: '',
        name: 'mWebHome',
        meta: {
          title: '首页'
        },
        component: () => import('@/views/mobile/Homepage')
      }
    ]
  },
  {
    path: '/nWebHome',
    name: 'nWebHome',
    // meta: {
    //   title: '首页'
    // },
    component: () => import('@/views/newHome/Index'),
    children: [
      {
        path: 'auCodeSearch',
        name: 'about',
        meta: {
          title: '授权码查询'
        },
        component: () => import('@/views/newHome/auCodeSearch/auCodeSearch')
      },
      {
        path: '',
        name: 'nWebHome',
        meta: {
          title: '新首页'
        },
        component: () => import('@/views/newHome/Home')
      },
      {
        path: 'IDInfo',
        name: 'IDInfo',
        meta: {
          title: '身份信息'
        },
        component: () => import('@/views/newHome/IDInfo/IDInfo')
      },
      {
        path: 'SocietyNegative',
        name: 'SocietyNegative',
        meta: {
          title: '社会负面'
        },
        component: () => import('@/views/newHome/SocietyNegative/SocietyNegative')
      },
      {
        path: 'FinanceRisk',
        name: 'FinanceRisk',
        meta: {
          title: '金融风险'
        },
        component: () => import('@/views/newHome/FinanceRisk/FinanceRisk')
      },
      {
        path: 'PersonalHonesty',
        name: 'PersonalHonesty',
        meta: {
          title: '个人诚信'
        },
        component: () => import('@/views/newHome/PersonalHonesty/PersonalHonesty')
      },
      {
        path: 'ProQualifications',
        name: 'ProQualifications',
        meta: {
          title: '专业资质'
        },
        component: () => import('@/views/newHome/ProQualifications/ProQualifications')
      },
      {
        path: 'ProSkills',
        name: 'ProSkills',
        meta: {
          title: '职业技能'
        },
        component: () => import('@/views/newHome/ProSkills/ProSkills')
      },
      {
        path: 'GlobalBackground',
        name: 'GlobalBackground',
        meta: {
          title: '国际背景核查'
        },
        component: () => import('@/views/newHome/GlobalBackground/GlobalBackground')
      },
      {
        path: 'SpecialInspection',
        name: 'SpecialInspection',
        meta: {
          title: '专项核查'
        },
        component: () => import('@/views/newHome/SpecialInspection/SpecialInspection')
      },
      {
        path: 'HelpCenter',
        name: 'HelpCenter',
        meta: {
          title: '帮助中心'
        },
        component: () => import('@/views/newHome/HelpCenter/HelpCenter')
      },
      {
        path: 'BePartner',
        name: 'BePartner',
        meta: {
          title: '成为渠道伙伴'
        },
        component: () => import('@/views/newHome/BePartner/BePartner')
      },
      {
        path: 'APIIntegration',
        name: 'APIIntegration',
        meta: {
          title: 'API集成'
        },
        component: () => import('@/views/newHome/APIIntegration/APIIntegration')
      },
      {
        path: 'BasicKnowledge',
        name: 'BasicKnowledge',
        meta: {
          title: '背调基础知识'
        },
        component: () => import('@/views/newHome/BasicKnowledge/BasicKnowledge')
      },
      {
        path: 'AboutUs',
        name: 'AboutUs',
        meta: {
          title: '关于我们'
        },
        component: () => import('@/views/newHome/AboutUs/AboutUs')
      },
      {
        path: 'ProductIntroduction',
        name: 'ProductIntroduction',
        meta: {
          title: '产品介绍'
        },
        component: () => import('@/views/newHome/ProductIntroduction/ProductIntroduction')
      },
      // {
      //   path: 'Solution',
      //   name: 'Solution',
      //   meta: {
      //     title: '解决方案'
      //   },
      //   component: () => import('@/views/newHome/Solution/Solution')
      // },
    ]
  },
  {
    path: '/webHome',
    name: 'webHome',
    redirect: '/welCome',
    component: () => import('@/views/officialWebsite/main/Index'),
    children: [
      {
        path: '/webHome',
        name: 'webHome',
        meta: {
          title: '首页'
        },
        component: () => import('@/views/officialWebsite/home/Home')
      },
      {
        path: '/userBacktone',
        name: 'userBacktone',
        meta: {
          title: '用户背调'
        },
        component: () => import('@/views/officialWebsite/userBacktone/UserBacktone')
      },
      {
        path: '/corporateBacktone',
        name: 'corporateBacktone',
        meta: {
          title: '企业背调'
        },
        component: () => import('@/views/officialWebsite/corporateBacktone/CorporateBacktone')
      },
      {
        path: '/overseasBacktone',
        name: 'overseasBacktone',
        meta: {
          title: '海外背调'
        },
        component: () => import('@/views/officialWebsite/overseasBacktone/OverseasBacktone')
      },
      {
        path: '/help',
        name: 'help',
        meta: {
          title: '帮助中心'
        },
        component: () => import('@/views/officialWebsite/help/Help')
      },
      {
        path: '/userHelp',
        name: 'userHelp',
        meta: {
          title: '候选人帮助'
        },
        component: () => import('@/views/officialWebsite/help/UserHelp')
      },
      {
        path: '/becomeBoss',
        name: 'becomeBoss',
        meta: {
          title: '成为渠道伙伴'
        },
        component: () => import('@/views/officialWebsite/help/BecomeWoker')
      },
      {
        path: '/apiCollect',
        name: 'apiCollect',
        meta: {
          title: 'API集成'
        },
        component: () => import('@/views/officialWebsite/help/ApiCollect')
      },
      {
        path: '/basicKnowledge',
        name: 'basicKnowledge',
        meta: {
          title: '帮助中心'
        },
        component: () => import('@/views/officialWebsite/basicKnowledge/BasicKnowledge')
      },
      {
        path: '/about',
        name: 'about',
        meta: {
          title: '帮助中心'
        },
        component: () => import('@/views/officialWebsite/about/About')
      },
      {
        path: '/auCodeSearch',
        name: 'about',
        meta: {
          title: '授权码查询'
        },
        component: () => import('@/views/officialWebsite/auCodeSearch/auCodeSearch')
      }
    ]
  }
]

let router = new Router({
  routes: constRouter
})

const whiteList = ['/IDInfo', '/nWebHome', '/mWebHome', '/webHome', '/userBacktone', '/corporateBacktone', '/overseasBacktone', '/help', '/userHelp', '/becomeBoss', '/apiCollect', '/basicKnowledge', '/about', '/auCodeSearch']

let asyncRouter

// 导航守卫，渲染动态路由
router.beforeEach((to, from, next) => {
  // if (whiteList.indexOf(to.path) !== -1) {
    if (to.path === '/webHome' && determineIsMobile()) {
      return next('/mWebHome')
    } else {
      next()
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      })
      return 
    }
  // }
  if (location.pathname === '/') return next('/webHome')
  let token = db.get('USER_TOKEN')
  let user = db.get('USER')
  let userRouter = get('USER_ROUTER')
  if (token.length && user) {
    if (!asyncRouter) {
      if (!userRouter) {
        request.get(`menu/${user.username}`).then((res) => {
          asyncRouter = res.data
          save('USER_ROUTER', asyncRouter)
          go(to, next)
        }).catch(err => { console.error(err) })
      } else {
        asyncRouter = userRouter
        go(to, next)
      }
    } else {
      next()
    }
  } else {
    next('/webHome')
  }
})

function go (to, next) {
  asyncRouter = filterAsyncRouter(asyncRouter)
  router.addRoutes(asyncRouter)
  next({...to, replace: true})
}

function save (name, data) {
  localStorage.setItem(name, JSON.stringify(data))
}

function get (name) {
  return JSON.parse(localStorage.getItem(name))
}

function filterAsyncRouter (routes) {
  return routes.filter((route) => {
    let component = route.component
    if (component) {
      switch (route.component) {
        default:
          route.component = view(component)
      }
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children)
      }
      return true
    }
  })
}

function view (path) {
  return function (resolve) {
    import(`@/views/${path}.vue`).then(mod => {
      resolve(mod)
    })
  }
}

export default router
